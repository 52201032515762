.harbor-table-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  position: relative;

  .react-bootstrap-table {
    border: none !important;
  }

  thead {
    background-color: $hBlue !important;

    tr {
      background-color: $hBlue !important;
    }
  }

  th {
    border: none !important;
    background-color: $hBlue;
    user-select: none;
    @extend .px-2;

    &:first-of-type {
      @extend .pl-3;
    }

    &:last-of-type {
      @extend .pr-3;
    }
  }

  td {
    border: none !important;
    padding-top: 0.7rem !important;
    padding-bottom: 0.7rem !important;
    vertical-align: middle;
    @extend .px-2;

    &:first-of-type {
      @extend .pl-3;
    }

    &:last-of-type {
      @extend .pr-3;
    }

    button {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      margin-right: $spacer;

      //&:last-of-type {
      //  @extend .mr-0;
      //}
    }
  }
}

.harbor-table-sorter {
  svg {
    font-size: 17px;
    color: white;
    display: block;
    opacity: 0.6;

    &:first-of-type {
      margin-bottom: -5px;
    }

    &.selected-sort {
      opacity: 1;
    }
  }
}

.table-button-menu-item-list {
  position: absolute;
  top: 7px;
  right: 25%;

  .menu-list-item-arrow-base {
    position: absolute;
    transform: rotate(45deg);
  }

  .menu-item-list-arrow-a {
    right: 14px;
    width: 20px;
    height: 20px;
    background-color: $hPurple;
  }

  .menu-item-list-arrow-b {
    z-index: 15;
    right: 16px;
    top: 2px;
    width: 16px;
    height: 16px;
    background-color: white;
  }

  .table-menu-list-content {
    display: flex !important;
    flex-direction: column !important;
    position: relative;
    z-index: 10;
    margin-top: 6px;
    background-color: white;
    width: 240px;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border: 2px solid $hPurple;
    border-radius: 0.4rem;
  }

  button {
    font-size: 0.95rem;
    opacity: 0.65;
    width: 100%;
    text-align: left;
    margin-right: 0 !important;

    &:hover {
      text-decoration: none !important;
      opacity: 1;
    }
  }
}

.cell-140 {
  max-width: 140px;
}

.cell-180 {
  max-width: 180px;
}

.cell-200 {
  max-width: 200px;
}
