// colors, done in this file to prevent issues with other files & import orders with the bootstrap-overrides file messing things up
$hPurple: #00154f;
$hBlue: #2d0966;
$hGray: #f0f0f0;

$theme-colors: (
        "hPurple": $hPurple,
        "hBlue": $hBlue,
        "hDark": rgb(173, 173, 173),
        "hLight": rgb(242, 242, 242),
        "hGray": $hGray,
);

//$btn-border-radius: 0.4rem;

$htc-container-width: 1600px;
$htc-container-width-narrow: 1200px;
