@import "./variables";

$neutral: rgba(0,0,0,.5);
$hover: rgba(0,0,0,.9) !important;

.header-logo {
  max-height: 85px;
  max-width: 240px;
  object-fit: contain;

  @media screen and (max-width: 575px) {
    max-width: 200px;
  }
}

.navbar-toggler {
  border: none !important;
  outline: none !important;
  padding: 0px !important;

  color: $neutral;
  transition: 0.25s;

  &:hover {
    color: $hover
  }
}

// hover

.dropdown-toggler {
  &:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  right: 0;
  min-width: 200px;
  margin-top: -5px;
}

.dropdown:hover .dropdown-content {display: block;}
.dropdown:hover .dropdown-toggler {background-color: rgba(0,0,0,0.05)}

.header-container {
  a, .dropdown-toggler, {
    color: $hPurple !important;
    opacity: 0.8 !important;
    font-size: 1.1rem !important;

    &:hover {
      opacity: 1 !important;
    }

    &.active {
      opacity: 1 !important;
      font-weight: bold !important;
    }
  }

  .header-mobile-account-label {
    color: $hPurple !important;
  }
}
