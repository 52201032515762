.document-viewer-link {
  color: $hPurple !important;
}

.document-list-item-delete-icon-svg {
  height: 1.6rem;

  &:hover {
    opacity: 0.85;
  }
}

.invoice-list-item-delete-icon-svg {
  @extend .document-list-item-delete-icon-svg;
}
