.po-inv-card-base {
  border: 2px solid transparent;
}

.po-inv-card-highlighted {
  border: 2px $hPurple solid;
}

.po-inv-card-row {
  padding-left: -1rem;
  padding-right: -1rem;
  display: flex;
  flex-wrap: wrap;
}

.po-inv-card-col {
  padding-left: 1rem;
  padding-right: 1rem;

  width: 100%;

  @media screen and (min-width: 691px) {
    width: 50%;
  }

  @media screen and (min-width: 1097px) {
    width: 33%;
  }

  @media screen and (min-width: 1400px) {
    width: 25%;
  }
}

