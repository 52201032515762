@import "./style/variables";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
//@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap");
//@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

$container-max-widths: (
  //sm: 540px,
  //md: 720px,
  //lg: 960px,
  //xl: 1140px
  //sm: 1600px,
  //md: 1600px,
  //lg: 1600px,
  xl: 1600px,
);

//// colors
//$hPurple: #310966;
//$hBlue: #27A9E1;
//
//$theme-colors: (
//  "hPurple": $hPurple,
//  "hBlue": $hBlue,
//  "hDark": rgb(173, 173, 173),
//  "hLight": rgb(242, 242, 242),
//);

.box-shadow-helper {
  -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
}

// input
$input-border-width: 2px;
$input-focus-border-color: $hPurple;
$input-focus-box-shadow: none !important;

// button
$btn-focus-box-shadow: none !important;
$button-border-radius: 4px !important;
$btn-border-radius: $button-border-radius;
$btn-border-radius-sm: $button-border-radius;
$btn-border-radius-lg: $button-border-radius;
.dashboard-card .btn {
  @extend .box-shadow-helper;
}

//$font-family-base: "Montserrat", sans-serif !default;
//$font-family-base: "Raleway", sans-serif !important;
$font-family-base: "Roboto", sans-serif !important;

.modal-dialog {
  max-width: 640px !important;
}

.dropdown-item:active {
  background-color: transparent !important;
}

.modal-header {
  @extend .p-2;
}

.card {
  border-radius: 0.5rem;
  @extend .box-shadow-helper;
}

.card-header {
  @extend .h2;
  @extend .p-4;
  background-color: $hBlue;
  color: white;
  border: none;
}

.card-title {
  @extend .p-3;
  padding-bottom: 0 !important;
  @extend .mb-0;
}

@function get-breakpoints($key: "md") {
  @return map-get($grid-breakpoints, $key);
}

label {
  color: $hPurple;
  font-weight: 600;
}

.w-17-5 {
  width: 17.5%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

$input-border-color: $hPurple;
$input-border-width: 1px;
$input-color: black;
$input-focus-color: black;
$input-placeholder-color: rgba($hPurple, 0.85);
$blue: $hBlue;
$primary: $hBlue;
