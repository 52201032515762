.htc-checkbox-container {
  display: inline-flex;
  cursor: pointer;

  .htc-checkbox {
    min-height: 1.4rem;
    min-width: 1.4rem;
    max-height: 1.4rem;
    max-width: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $hBlue;
    border-radius: 5px;
    margin-right: 0.5rem;
    opacity: 1;
  }

  .htc-checkbox-checked {
    background-color: $hBlue;
  }

  .htc-checkbox-unchecked {
    background-color: transparent;
  }

  .htc-checkbox-label {
    opacity: 1;
    margin-top: -1px;
  }

  &:hover {
    .htc-checkbox {
      opacity: 0.9;
    }

    .htc-checkbox-label {
      opacity: 0.9;
    }
  }
}

