.htc-modal {
  //@extend .box-shadow-helper;
  .modal-content {
    border: none !important;
    margin: 1rem;
  }
}

.htc-close-modal-icon-base {
  position: absolute;
  border-radius: 50%;

  height: 40px;
  width: 40px;
  right: -15px;
  top: -10px;

}

.htc-close-modal-icon-bg {
  @extend .htc-close-modal-icon-base;
  background-color: white;
}

.htc-close-modal-icon-fg {
  @extend .htc-close-modal-icon-base;
  background-color: $hPurple;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  &:hover {
    background-color: rgba($hPurple, 0.9);
  }

  span {
    color: white;
    font-weight: bold;
  }
}
