.upload-btn-wrapper-inner {
  //border: 2px dashed #5bc0de;
}

.upload-btn-wrapper input[type=file] {
  font-size: 1000px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
