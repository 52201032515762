.modal-header-contact {
  border-bottom-width: 0px !important;
}

.modal-header-contact > .close:focus {
  outline: none;
}

.modal-footer-contact {
  border-top-width: 0px !important;
}

.form-control:focus {
  box-shadow: none !important;
}
