$po-invoice-breakpoint: 902px;
$po-invoice-breakpoint-sub-cards-1: 790px;
$po-invoice-breakpoint-sub-cards-2: 653px;
$po-invoice-button-margin-breakpoint-helper: 400px;

.upload-po-or-invoice-button-container-a {
  display: none;

  @media screen and (max-width: $po-invoice-breakpoint) {
    display: flex;
    justify-content: center;
  }
}

.upload-po-or-invoice-button-container-b {
  display: flex;
  justify-content: center;

  @media screen and (max-width: $po-invoice-breakpoint) {
    display: none;
  }
}

.po-invoice-form-container {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: $po-invoice-breakpoint) {
    background-color: transparent;
    display: block;
  }
}

.po-invoice-main {
  width: 100%;
}

.po-invoice-sub {
  min-width: 320px;
  max-width: 320px;
  padding-left: 1rem;

  .po-invoice-sub-card-container {
    margin-bottom: 1.5rem;
  }

  .po-invoice-sub-container-middle {
    padding: 0;
  }

  @media screen and (max-width: $po-invoice-breakpoint) {
    padding-left: 0;
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    max-width: 100%;
    min-width: 0;

    .po-invoice-sub-card-container {
      width: 100%;
      max-width: 280px;
    }

    .po-invoice-sub-container-middle {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .po-invoice-sub-container-middle-pseudo {
      margin-right: 1rem;
    }
  }

  @media screen and (max-width: 623px) {
    .po-invoice-sub-container-middle-pseudo {
      margin-left: 1rem;
    }
  }

  @media screen and (max-width: $po-invoice-breakpoint-sub-cards-1) {
    flex-wrap: wrap;
    justify-content: center;

    .po-invoice-sub-card-container {
      width: 100%;
      min-width: 300px;
      max-width: 300px;
      margin-bottom: 1.5rem;
    }

    .po-invoice-sub-container-middle {
      padding: 0;
      margin-left: 1.5rem;
    }
  }

  @media screen and (max-width: $po-invoice-breakpoint-sub-cards-2) {
    .po-invoice-sub-container-middle {
      margin-right: 1.5rem;
    }
  }

  @media screen and (max-width: $po-invoice-button-margin-breakpoint-helper) {
    .po-invoice-sub-container-middle {
      margin-left: 0;
      margin-right: 0;
    }

    .po-invoice-sub-card-container {
      min-width: 280px;
      max-width: 100%;
    }
  }
}

.po-save-button-display-manager {
  .po-buttons-exterior {
    display: none;
  }

  .po-buttons-interior {
    display: initial;
  }

  @media screen and (max-width: $po-invoice-breakpoint) {
    .po-buttons-exterior {
      display: initial;
    }

    .po-buttons-interior {
      display: none;
    }
  }
}

.po-invoice-save-submit-buttons {
  > div {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    flex-wrap: wrap;
  }

  .po-second-button {
    margin-left: 1.5rem;
  }

  @media screen and (max-width: $po-invoice-breakpoint) {
    > div {
      justify-content: center;
    }
  }

  @media screen and (max-width: 637px) {
    .po-second-button {
      margin-right: 1.5rem;
    }
  }

  @media screen and (max-width: $po-invoice-button-margin-breakpoint-helper) {
    .po-second-button {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.po-invoice-error-helper {
  .po-errors-exterior {
    display: none;
  }

  .po-errors-interior {
    display: initial;
  }

  @media screen and (max-width: $po-invoice-breakpoint) {
    .po-errors-exterior {
      display: initial;
    }

    .po-errors-interior {
      display: none;
    }
  }
}

.po-invoice-sub-header-button-a {
  display: initial;
}

.po-invoice-sub-header-button-b {
  display: none;
}

@media screen and (max-width: 560px) {
  .po-invoice-sub-header-button-a {
    display: none;
  }

  .po-invoice-sub-header-button-b {
    display: initial;
  }
}

.add-edit-supplier-customer-button-container {
  display: flex;
  align-items: center;

  .input-max-width-600 {
    min-width: 300px;
  }

  .ae-sc-btn-inner {
    margin-left: 1.5rem;

    button {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  @media screen and (max-width: 600px) {
    display: block;

    .input-max-width-600 {
      min-width: 0;
    }

    .ae-sc-btn-inner {
      margin-left: 0;
      margin-top: 1.5rem;
      display: flex;
      justify-content: center;

      button {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }
  }
}
