.tab-selector-item {
  @extend .box-shadow-helper;
  cursor: pointer;
  min-width: 160px;
  padding: 0.5rem 1rem;
  text-align: center;
  background-color: white;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  font-weight: lighter;
  color: $hPurple;
  white-space: nowrap;
  font-size: 0.95rem;
  border-top: 1px solid;
  border-top-color: rgba(0, 0, 0, 0.075);

  &:hover {
    background-color: rgba($hPurple, 0.025);
  }

  &.tab-selector-item-active {
    //@extend .font-weight-semi-bold;
    font-weight: 500;
    color: white;
    background-color: $hPurple;
    border-top-color: $hPurple;
  }
}
