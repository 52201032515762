@import "../bootstrap-override";

.dashboard-last-log-in-welcome-message:first-letter {
  text-transform: capitalize;
}

.dashboard-card {
  .card-body {
    @extend .px-4;
    @extend .py-4;
  }

  .custom-dashboard-card-child {
    z-index: 3;
  }
}

.dashboard-background-card {
  position: relative;
  overflow: hidden;

  .card-body {
    z-index: 2;
  }

  .db-background-circle-container {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    div {
      position: absolute;
      width: 105%;
      height: 165px;
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }
}

.db-col {
  display: grid;
  gap: 1rem;
  align-items: start;
}

$xl-1448: 1448px;
$lg-1200: 1200px;

.db-col-main-xl {
  @media screen and (max-width: $xl-1448) {
    display: none !important;
  }
}

.db-col-main-lg {
  display: none;
  @media screen and (max-width: $xl-1448) {
    display: block !important;
  }
}

.db-wrapper {
  //grid-template-columns: 100%;
  //background-color: red;

  // working with a system of 5 columns, but sometimes there will only be 3 or 4 columns being utilized,
  // so in order to have those centered and look good, work with a system of 10 columns and use extra on the 1st & 5th in those cases

  //@media screen and (max-width: $xl-1448) {
  //  .db-col-spacer {
  //    display: none !important;
  //  }
  //}
  justify-content: center;

  &__221 {
    grid-template-columns: 0fr 4fr 4fr 2fr 0fr;

    .db-info-col {
      grid-template-columns: 1fr 1fr;
    }

    .db-actions-col {
      grid-template-columns: 1fr 1fr;
    }

    .db-misc-col {
      grid-template-columns: 1fr;
    }

    @media screen and (max-width: $xl-1448) {
      grid-template-columns: repeat(auto-fill, 600px);

      .db-misc-col {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  &__211 {
    grid-template-columns: 1fr 4fr 2fr 2fr 1fr;

    .db-info-col {
      grid-template-columns: 1fr 1fr;
    }

    .db-actions-col {
      grid-template-columns: 1fr;
    }

    .db-misc-col {
      grid-template-columns: 1fr;
    }

    @media screen and (max-width: $xl-1448) {
      grid-template-columns: 2fr 1fr 1fr;
    }

    @media screen and (max-width: $lg-1200) {
      grid-template-columns: 600px 300px 300px;

      .db-actions-col {
        grid-template-columns: 1fr;
        width: 300px;
      }

      .db-misc-col {
        grid-template-columns: 1fr;
        width: 300px;
      }
    }
  }

  &__111 {
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr;

    .db-info-col {
      grid-template-columns: 1fr;
    }

    .db-actions-col {
      grid-template-columns: 1fr;
    }

    .db-misc-col {
      grid-template-columns: 1fr;
    }
  }

  &__121 {
    grid-template-columns: 1fr 2fr 4fr 2fr 1fr;

    .db-info-col {
      grid-template-columns: 1fr;
    }

    .db-actions-col {
      grid-template-columns: 1fr 1fr;
    }

    .db-misc-col {
      grid-template-columns: 1fr;
    }
  }
}

$marginVal: 0.5rem;
$half-column: calc(50% - #{$marginVal * 2});

$xl-1486: 1486px;
$xl-minus-1: 1199px;
$lg-minus-1: 991px;
$md-minus-1: 767px;
$sm-minus-1: 575px;

.db-wrapper2 {
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  @media screen and (max-width: 575px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .db-info-col, .db-actions-col, .db-misc-col {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    >div {
      margin: $marginVal;
    }
  }

  &__221 {
    .db-info-col {
      width: 40%;
      > div {
        width: $half-column;
      }
    }

    .db-actions-col {
      width: 40%;
      > div {
        width: $half-column;
      }
    }

    .db-misc-col {
      width: 20%;
      >div {
        width: 100%;
      }
    }

    @media screen and (max-width: $xl-1486) {
      .db-info-col {
        width: 50%;
        > div {
          width: $half-column;
        }
      }

      .db-actions-col {
        width: 50%;
        > div {
          width: $half-column;
        }
      }

      .db-misc-col {
        width: 100%;
        >div {
          width: 25%;
        }
      }
    }

    @media screen and (max-width: $xl-minus-1) {
      .db-info-col {
        width: 80%;
        > div {
          width: $half-column;
        }
      }

      .db-actions-col {
        width: 80%;
        > div {
          width: $half-column;
        }
      }

      .db-misc-col {
        width: 80%;
        >div {
          width: $half-column;
        }
      }
    }

    @media screen and (max-width: $lg-minus-1) {
      .db-info-col {
        width: 100%;
        > div {
          width: $half-column;
        }
      }

      .db-actions-col {
        width: 100%;
        > div {
          width: $half-column;
        }
      }

      .db-misc-col {
        width: 100%;
        >div {
          width: $half-column;
        }
      }
    }

    @media screen and (max-width: $md-minus-1) {
      .db-info-col {
        width: 80%;
        > div {
          width: 100%;
        }
      }

      .db-actions-col {
        width: 80%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 80%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $sm-minus-1) {
      .db-info-col {
        width: 100%;
        > div {
          width: 100%;
        }
      }

      .db-actions-col {
        width: 100%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 100%;
        >div {
          width: 100%;
        }
      }
    }
  }

  &__211 {
    .db-info-col {
      width: 40%;
      > div {
        width: $half-column;
      }
    }

    .db-actions-col {
      width: 20%;
      > div {
        width: 100%;
      }
    }

    .db-misc-col {
      width: 20%;
      >div {
        width: 100%;
      }
    }

    @media screen and (max-width: $xl-1486) {
      .db-info-col {
        width: 50%;
        > div {
          width: $half-column;
        }
      }

      .db-actions-col {
        width: 25%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 25%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $xl-minus-1) {
      .db-info-col {
        width: 80%;
        > div {
          width: $half-column;
        }
      }

      .db-actions-col {
        width: 40%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 40%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $lg-minus-1) {
      .db-info-col {
        width: 100%;
        > div {
          width: $half-column;
        }
      }

      .db-actions-col {
        width: 50%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 50%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $md-minus-1) {
      .db-info-col {
        width: 80%;
        > div {
          width: 100%;
        }
      }

      .db-actions-col {
        width: 80%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 80%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $sm-minus-1) {
      .db-info-col {
        width: 100%;
        > div {
          width: 100%;
        }
      }

      .db-actions-col {
        width: 100%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 100%;
        >div {
          width: 100%;
        }
      }
    }
  }

  &__111 {
    .db-info-col {
      width: 20%;
      > div {
        width: 100%;
      }
    }

    .db-actions-col {
      width: 20%;
      > div {
        width: 100%;
      }
    }

    .db-misc-col {
      width: 20%;
      >div {
        width: 100%;
      }
    }

    @media screen and (max-width: $xl-1486) {
      .db-info-col {
        width: 28%;
        > div {
          width: 100%;
        }
      }

      .db-actions-col {
        width: 28%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 28%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $xl-minus-1) {
      .db-info-col {
        width: calc(100% /3);
        > div {
          width: 100%;
        }
      }

      .db-actions-col {
        width: calc(100% /3);
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: calc(100% /3);
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $lg-minus-1) {
      .db-info-col {
        width: 50%;
        > div {
          width: 100%;
        }
      }

      .db-actions-col {
        width: 50%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 100%;
        >div {
          width: $half-column;
        }
      }
    }

    @media screen and (max-width: $md-minus-1) {
      .db-info-col {
        width: 80%;
        > div {
          width: 100%;
        }
      }

      .db-actions-col {
        width: 80%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 80%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $sm-minus-1) {
      .db-info-col {
        width: 100%;
        > div {
          width: 100%;
        }
      }

      .db-actions-col {
        width: 100%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 100%;
        >div {
          width: 100%;
        }
      }
    }
  }

  &__121 {
    .db-info-col {
      width: 20%;
      > div {
        width: 100%;
      }
    }

    .db-actions-col {
      width: 40%;
      > div {
        width: $half-column;
      }
    }

    .db-misc-col {
      width: 20%;
      >div {
        width: 100%;
      }
    }

    @media screen and (max-width: $xl-1486) {
      .db-info-col {
        width: 25%;
        > div {
          width: 100%;
        }
      }

      .db-actions-col {
        width: 50%;
        > div {
          width: $half-column;
        }
      }

      .db-misc-col {
        width: 25%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $xl-minus-1) {
      .db-info-col {
        width: 40%;
        > div {
          width: 100%;
        }
      }

      .db-actions-col {
        width: 40%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 80%;
        >div {
          width: $half-column;
        }
      }
    }

    @media screen and (max-width: $lg-minus-1) {
      .db-info-col {
        width: 50%;
        > div {
          width: 100%;
        }
      }

      .db-actions-col {
        width: 50%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 100%;
        >div {
          width: $half-column;
        }
      }
    }

    @media screen and (max-width: $md-minus-1) {
      .db-info-col {
        width: 80%;
        > div {
          width: 100%;
        }
      }

      .db-actions-col {
        width: 80%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 80%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $sm-minus-1) {
      .db-info-col {
        width: 100%;
        > div {
          width: 100%;
        }
      }

      .db-actions-col {
        width: 100%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 100%;
        >div {
          width: 100%;
        }
      }
    }
  }

  &__001 {
    .db-info-col {
      display: none;
      > div {
        width: 0%;
      }
    }

    .db-actions-col {
      display: none;
      > div {
        width: 0%;
      }
    }

    .db-misc-col {
      width: 40%;
      >div {
        width: $half-column;
      }
    }

    @media screen and (max-width: $xl-1486) {
      .db-misc-col {
        width: 50%;
        >div {
          width: $half-column;
        }
      }
    }

    @media screen and (max-width: $xl-minus-1) {
      .db-misc-col {
        width: 80%;
        >div {
          width: $half-column;
        }
      }
    }

    @media screen and (max-width: $lg-minus-1) {
      .db-misc-col {
        width: 100%;
        >div {
          width: $half-column;
        }
      }
    }

    @media screen and (max-width: $md-minus-1) {
      .db-misc-col {
        width: 80%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $sm-minus-1) {
      .db-misc-col {
        width: 100%;
        >div {
          width: 100%;
        }
      }
    }
  }

  &__011 {
    .db-info-col {
      display: none;
      > div {
        width: 0%;
      }
    }

    .db-actions-col {
      width: 40%;
      > div {
        width: $half-column;
      }
    }

    .db-misc-col {
      width: 40%;
      >div {
        width: $half-column;
      }
    }

    @media screen and (max-width: $xl-1486) {
      .db-actions-col {
        width: 50%;
        > div {
          width: $half-column;
        }
      }

      .db-misc-col {
        width: 50%;
        >div {
          width: $half-column;
        }
      }
    }

    @media screen and (max-width: $xl-minus-1) {
      .db-actions-col {
        width: 40%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 40%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $lg-minus-1) {
      .db-actions-col {
        width: 50%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 50%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $md-minus-1) {
      .db-actions-col {
        width: 80%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 80%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $sm-minus-1) {
      .db-actions-col {
        width: 100%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 100%;
        >div {
          width: 100%;
        }
      }
    }
  }

  &__021 {
    .db-info-col {
      display: none;
      > div {
        width: 0%;
      }
    }

    .db-actions-col {
      width: 40%;
      > div {
        width: $half-column;
      }
    }

    .db-misc-col {
      width: 20%;
      >div {
        width: 100%;
      }
    }

    @media screen and (max-width: $xl-1486) {
      .db-actions-col {
        width: 50%;
        > div {
          width: $half-column;
        }
      }

      .db-misc-col {
        width: 25%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $xl-minus-1) {
      .db-actions-col {
        width: calc((100% / 3) * 2);
        > div {
          width: $half-column;
        }
      }

      .db-misc-col {
        width: calc(100% / 3);
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $lg-minus-1) {
      .db-actions-col {
        width: 100%;
        > div {
          width: $half-column;
        }
      }

      .db-misc-col {
        width: 100%;
        >div {
          width: $half-column;
        }
      }
    }

    @media screen and (max-width: $md-minus-1) {
      .db-actions-col {
        width: 80%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 80%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $md-minus-1) {
      .db-actions-col {
        width: 100%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 100%;
        >div {
          width: 100%;
        }
      }
    }
  }

  &__101 {
    .db-info-col {
      width: 20%;
      > div {
        width: 100%;
      }
    }

    .db-actions-col {
      display: none;
      > div {
        width: $half-column;
      }
    }

    .db-misc-col {
      width: 20%;
      >div {
        width: 100%;
      }
    }

    @media screen and (max-width: $xl-1486) {
      .db-info-col {
        width: 28%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 28%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $xl-minus-1) {
      .db-info-col {
        width: 40%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 40%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $lg-minus-1) {
      .db-info-col {
        width: 50%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 50%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $md-minus-1) {
      .db-info-col {
        width: 80%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 80%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $sm-minus-1) {
      .db-info-col {
        width: 100%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 100%;
        >div {
          width: 100%;
        }
      }
    }
  }

  &__201 {
    .db-info-col {
      width: 40%;
      > div {
        width: $half-column;
      }
    }

    .db-actions-col {
      display: none;
      > div {
        width: 0%;
      }
    }

    .db-misc-col {
      width: 20%;
      >div {
        width: 100%;
      }
    }

    @media screen and (max-width: $xl-1486) {
      .db-info-col {
        width: 50%;
        > div {
          width: $half-column;
        }
      }

      .db-misc-col {
        width: 25%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $xl-minus-1) {
      .db-info-col {
        width: calc((100% / 3) * 2);
        > div {
          width: $half-column;
        }
      }

      .db-misc-col {
        width: calc(100% / 3);
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $lg-minus-1) {
      .db-info-col {
        width: 100%;
        > div {
          width: $half-column;
        }
      }

      .db-misc-col {
        width: 100%;
        >div {
          width: $half-column;
        }
      }
    }

    @media screen and (max-width: $md-minus-1) {
      .db-info-col {
        width: 80%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 80%;
        >div {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $sm-minus-1) {
      .db-info-col {
        width: 100%;
        > div {
          width: 100%;
        }
      }

      .db-misc-col {
        width: 100%;
        >div {
          width: 100%;
        }
      }
    }
  }
}
