$invoice-items-breakpoint-a: 1180px;
$invoice-items-breakpoint-b: 1060px;

.invoice-items {
  display: flex;
  flex-wrap: wrap;

  > div {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 0.5rem;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  .invoice-item-name {
    width: 18%;
  }

  .invoice-item-description {
    width: 32%;
  }

  .invoice-item-quantity {
    width: 15%;
  }

  .invoice-item-price-per {
    width: 15%;
  }

  .invoice-item-total {
    width: 15%;
    white-space: pre;
    overflow-x: auto;
    vertical-align: center;
    padding-top: 6px;
  }

  .invoice-item-delete-icon {
    width: 5%;
    margin-top: -1px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: $invoice-items-breakpoint-a) {
    > div {
      padding-left: 0;
    }

    .invoice-item-name {
      width: 40%;
      margin-right: 60%;
    }

    .invoice-item-description {
      width: 40%;
      margin-right: 60%;
    }

    .invoice-item-quantity {
      width: 20%;
    }

    .invoice-item-price-per {
      width: 20%;
    }

    .invoice-item-total {
      width: 55%;
      text-align: right;
    }

    .invoice-item-delete-icon {
      width: 5%;
    }
  }

  @media screen and (max-width: $invoice-items-breakpoint-b) {
    .invoice-item-name {
      width: 52%;
      margin-right: 48%;
    }

    .invoice-item-description {
      width: 52%;
      margin-right: 48%;
    }

    .invoice-item-quantity {
      width: 26%;
    }

    .invoice-item-price-per {
      width: 26%;
    }

    .invoice-item-total {
      width: 43%;
    }

    .invoice-item-delete-icon {
      width: 5%;
    }
  }

  @media screen and (max-width: $po-invoice-breakpoint) {
    .invoice-item-name {
      width: 18%;
      margin-right: 0;
    }

    .invoice-item-description {
      width: 32%;
      margin-right: 0;
    }

    .invoice-item-quantity {
      width: 15%;
    }

    .invoice-item-price-per {
      width: 15%;
    }

    .invoice-item-total {
      width: 15%;
    }

    .invoice-item-delete-icon {
      width: 5%;
    }
  }

  @media screen and (max-width: 810px) {
    .invoice-item-name {
      width: 50%;
      margin-right: 50%;
    }

    .invoice-item-description {
      width: 50%;
      margin-right: 50%;
    }

    .invoice-item-quantity {
      width: 25%;
    }

    .invoice-item-price-per {
      width: 25%;
    }

    .invoice-item-total {
      width: 45%;
      text-align: right;
    }

    .invoice-item-delete-icon {
      width: 5%;
    }
  }

  @media screen and (max-width: 560px) {
    > div {
      padding-right: 0;
    }

    .invoice-item-name {
      width: 100%;
      margin-right: 0;
    }

    .invoice-item-description {
      width: 100%;
      margin-right: 0;
    }

    .invoice-item-quantity {
      width: 50%;
      padding-right: 0.5rem;
    }

    .invoice-item-price-per {
      width: 50%;
    }

    .invoice-item-total {
      width: 85%;
      text-align: left;
      padding-top: 0px;
    }

    .invoice-item-delete-icon {
      justify-content: flex-end;
      padding-left: 0.5rem;
      width: 15%;
    }
  }
}
