@import "../bootstrap-override.scss";
@import "./checkbox.scss";
@import "./modal";
@import "./dashboard-custom-cols.scss";
@import "./tradelink-form.scss";
@import "./dashboard.scss";
@import "./Header.scss";
@import "./tables.scss";
@import "./button-width-mods.scss";
@import "./tab-selector.scss";
@import "./filters.scss";
@import "./htc-containers.scss";
@import "./po-invoices-pages.scss";
@import "./order-items.scss";
@import "./react-select-style-overrides.scss";
@import "./htc-checkbox.scss";
@import "./errors.scss";
@import "./links.scss";
@import "./htc-modal.scss";
@import "./account-info.scss";
@import "./po-invoice-card-management.scss";
@import "./login-redesign.scss";

body {
  overflow-x: hidden;
  //font-family: "Montserrat", sans-serif;
}

.dashboard-page {
  //font-family: "Raleway", sans-serif !important;
}

a.a-white {
  color: white;

  &:hover {
    color: darken(white, 15%);
  }
}

button:focus {
  outline: none !important;
}

h1, .h1 {
  //font-weight: 700 !important;
  font-weight: 500 !important;
  font-size: 2.3rem !important;
}

h2, .h2 {
  font-weight: 500 !important;
  font-size: 20pt;
}

.font-weight-semi-bold {
  font-weight: 500 !important;
}

th {
  white-space: nowrap;
  padding: 0 10px 0 10px !important;
}

td {
  padding: 0 10px 0 10px !important;
}

textarea {
  min-height: 120px;
  max-height: 600px;
}

input[type='date']:after {
  content: attr(placeholder)
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
  cursor: pointer;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 99999s ease-in-out 0s, color 99999s ease-in-out 0s, font-size 99999s ease-in-out 0s !important;
  font-size: 1rem !important;
}

::-webkit-input-placeholder {
  font-style: italic;
}
:-moz-placeholder {
  font-style: italic;
}
::-moz-placeholder {
  font-style: italic;
}
:-ms-input-placeholder {
  font-style: italic;
}

.tab-table-fix {
  margin-bottom: -2px;
}

.tab-scroller {
  flex-wrap: nowrap !important;
  overflow-x: scroll;
  overflow-y: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-x-scroller {
  white-space: nowrap;
  overflow-x: auto;
}

.modal-max-200 {
  max-width: 200px !important;
}

.modal-max-300 {
  max-width: 300px !important;
}

.modal-max-400 {
  max-width: 400px !important;
}

.modal-max-500 {
  max-width: 500px !important;
}

.modal-max-600 {
  max-width: 600px !important;
}

.modal-max-700 {
  max-width: 700px !important;
}

.modal-max-800 {
  max-width: 800px !important;
}

.modal-max-900 {
  max-width: 900px !important;
}

.modal-max-1200 {
  max-width: 1200px !important;
}

.react-bootstrap-table th {
  outline: none !important;
}

.react-bootstrap-table table {
  table-layout: initial !important;
  margin-bottom: 0;
}

.error-modal {
  min-width: 300px !important; // minimum width for error modal
}

.react-datepicker-wrapper-parent {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

.number-display {
  //font-family: "Open Sans", serif;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.white-space-break-spaces {
  white-space: break-spaces !important;
}

.font-size-145rem {
  font-size: 1.45rem;
}

.card-summary-mock-account-overview {
  @extend .font-weight-normal;
  @extend .text-right;
  @extend .h4;
  @extend .font-size-145rem;
}

.input-max-width-300 {
  max-width: 300px;
}

.input-max-width-600 {
  max-width: 600px;
}

.input-max-width-900 {
  max-width: 900px;
}

select {

  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/svg_icons/select-dropdown-blue.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 8px);
  background-position-y: 8px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;

  &:disabled {
    background-image: url("/svg_icons/select-dropdown-purple.svg");
  }

  &.react-datepicker__month-select, &.react-datepicker__year-select {
    background: initial;
    background-image: initial;
    background-repeat: initial;
    background-position-x: initial;
    background-position-y: initial;
    margin-right: initial;
    padding: initial;
    padding-right: initial;

    appearance: menulist;
    box-sizing: border-box;

    &:disabled {
      background-image: initial;
    };
  }
}

.un-selected-drop-down {
  color: $hPurple !important;
  font-style: italic;
}

.un-selected-drop-down-parent {
  select {
    @extend .un-selected-drop-down;
  }
}

.link-button {
  box-shadow: none !important;
}

.form-tip {
  font-size: 0.9rem;
}
