.login-page-bg {
  height: 100vh;
  background-color: white;

  @media screen and (min-width: 1200px) {
    background-color: $hPurple;
  }
}

//

.login-carousel {
  min-height: 60vh;

  display: flex;
  align-items: center;
}

.carousel-inner {
  height: 100% !important;
}

.login-carousel-message-board {
  padding: 1.5rem;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-carousel-message-board-desktop {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  width: 100%;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    font-size: 2.75rem !important;
  }
}

.login-carousel-btn-container {
  width: 100%;
  max-width: 240px;
}

$selectorSize: 12px;

.login-carousel-selector-container {
  display: flex;
  justify-content: center;
  margin-left: calc(-1 * #{$selectorSize} / 2.5);
  margin-right: calc(-1 * #{$selectorSize} / 2.5);
}

.login-carousel-selector-container-desktop {
  display: flex;
  margin-left: calc(-1 * #{$selectorSize} / 2.5);
  margin-right: calc(-1 * #{$selectorSize} / 2.5);
}

.login-carousel-selector {
  margin-left: calc(#{$selectorSize} / 2.5);
  margin-right: calc(#{$selectorSize} / 2.5);
  width: $selectorSize;
  height: $selectorSize;
  border-radius: 50%;
  background-color: $hPurple;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
}

.login-carousel-selector-selected {
  opacity: 1;

  &:hover {
    opacity: 1 !important;
  }
}

//

.login-form-mobile-container {
  width: 100%;
  max-width: 500px;
}

//

.login-display-mobile {
  @media screen and (min-width: 1200px) {
    display: none;
  }
}

.login-display-desktop {
  display: none;

  @media screen and (min-width: 1200px) {
    display: initial;
  }
}

.login-desktop-layout-manager {
  width: 100%;
  position: relative;
  overflow: hidden;
  //min-height: 750px;

  .login-main-bg-area {
    //position: relative;
    //height: 100%;
    //width: 70%;
    width: 115%;
  }

  .login-svg-side {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;

    .login-form-desktop {
      position: absolute;
      top: 0%;
      right: 2vw;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      max-width: 300px;
      pointer-events: all;
      //transform: translate(0%, -7%);

      @media screen and (min-width: 1200px) {
        max-width: 300px;
      }

      @media screen and (min-width: 1360px) {
        max-width: 320px;
      }

      @media screen and (min-width: 1420px) {
        max-width: 340px;
      }

      @media screen and (min-width: 1540px) {
        max-width: 360px;
      }

      @media screen and (min-width: 1600px) {
        max-width: 400px;
      }

      @media screen and (min-width: 1770px) {
        max-width: 460px;
      }
    }
  }
}

/** div that covers a small issue where the carousel image is visible underneath the content **/
.login-desktop-bottom-curtain {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: $hPurple;
  height: 3px;
  z-index: 100;
}

.login-min-height {
  min-height: 800px;
}

.login-desktop-footer {
  position: absolute;
  top: 0;
  right: 0;
}

.reactstrap-carousel-selector-helper {
  height: inherit;

  .carousel-inner {
    height: inherit;
  }

  .carousel-item {
    height: inherit;

    img {
      height: inherit;
    }
  }
}
