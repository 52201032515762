.buyer-setup-tradelink-form {
  .btn-group {
    display: block;
    .btn {
      width: 100px !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
