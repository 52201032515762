.button-width-100 {
  width: 100px;
  max-width: 100%;
}

.button-width-140 {
  width: 140px;
  max-width: 100%;
}

.button-width-200 {
  width: 200px;
  max-width: 100%;
}

.button-width-280 {
  width: 280px;
  max-width: 100%;
}
