.db-summary-custom-col {
  @extend .col-lg-12;

  @media screen and (min-width: 900px) {
    min-width: 560px;
    max-width: 560px;
  }
}

.things-to-do-custom-col {
  @extend .col-md-12;

  @media screen and (min-width: 1411px) {
    min-width: 30%;
  }
}
