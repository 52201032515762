.css-2b097c-container { // main react-select container

  .css-1pahdxg-control {
    border-color: $hPurple !important;
    box-shadow: none;
    &:hover {
      border-color: $hPurple !important;
      box-shadow: none;
    }
  }

  .css-yk16xz-control { // inner container
    border-color: $hPurple !important;
  }

  .css-g1d714-ValueContainer { // left half / value display
    .css-1wa3eu0-placeholder {
      @extend .un-selected-drop-down;
    }

    .css-1uccc91-singleValue {
      color: black;
    }
  }

  .css-1hb7zxy-IndicatorsContainer { // right half / icon display
    .css-1okebmr-indicatorSeparator { // horizontal separator
      //background-color: $hPurple;
      display: none;
    }

    .css-tlfecz-indicatorContainer:first-child { // div with the arrow icon
      > svg {
        display: none;
      }

      @extend select;
      background-image: url("/svg_icons/small_x_button.svg") !important;
      margin-right: 0 !important;
      background-position-y: 1px !important;
    }

    .css-1gtu0rj-indicatorContainer:first-child { // div with the arrow icon when the menu is open
      > svg {
        display: none;
      }

      @extend select;
      background-image: url("/svg_icons/small_x_button.svg") !important;
      margin-right: 0 !important;
      background-position-y: 1px !important;
    }

    .css-tlfecz-indicatorContainer:last-child { // div with the arrow icon
      > svg {
        display: none;
      }

      @extend select;
      margin-right: 0 !important;
      background-position-y: 6px !important;
      padding-right: 1rem !important;
    }

    .css-1gtu0rj-indicatorContainer:last-child { // div with the arrow icon when the menu is open
      > svg {
        display: none;
      }

      @extend select;
      margin-right: 0 !important;
      background-position-y: 6px !important;
      padding-right: 1rem !important;
    }
  }
}
